.Home {
  margin: 20px 20px 20px 200px;
  padding: 10px 0;
  height: 100%;
  font-family: Lato;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.p {
    color: #56595A;
    line-height: normal !important;
    margin: auto !important;
    font-weight: bold;
  }
  