.MemberInfo {
    margin: 50px;
    padding: 20px;
    font-size: 13px;
}

.memberInfoSpan {
    margin-top: 20px;
    margin-right: 480px;
}

.memberInfoButtonSection {
    text-align: center;
}

.memberInfoButton {
    background-color: #919192 !important;
    border: 2px solid #919192 !important;
    border-radius: 4px;
    vertical-align: middle;
    width: 90px;
    height: 40px;
    padding: 0;
    box-shadow: none;
    cursor: pointer;
    color: #FFFF;
}

.memberInfoButton:disabled {
    background-color: #DDDDDD !important;
    border: 2px solid #DDDDDD !important;
    border-radius: 4px;
    vertical-align: middle;
    width: 90px;
    height: 40px;
    padding: 0;
    cursor: not-allowed;
}

.memberInfoOccupationSelectedList {
    margin: 20px auto;
}

.fileLinkDisabled{
    cursor: not-allowed;
    display: inline-block;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
}
