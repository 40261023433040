.App {
  margin: 20px;
  padding: 0;
  height: 100%;
  min-height: 100%;
  font-family: Lato;
  font-size: 16px;
}

p {
  color: #56595A;
  line-height: normal !important;
  margin: auto !important;
}

/***Create animation when operations are executing.***/
.loaderStyle {
  background-color: rgba(255,255,255,.7);
  top: 0;
  left:0;
  height:100%;
  position: fixed;
  width: 100%;
  display: block;
  height: 1000px;
  text-align: center;
  z-index: 999999999999999;
}

@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}
.waitingSimple {
  top: 50vh;
  right: 45vw;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #979EA2;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
}


/**************************************/
/**Customize member search bar style**/
/************************************/
.ui.icon.input>i.icon {
  pointer-events: auto !important;
  cursor: pointer !important;
}

/***********************************/
/**Customize Swal buttons style**/
/********************************/

.swal-button {
  background-color: #919192 !important;
}

.swal-button:not([disabled]):hover {
  background-color: #919192 !important;
}

/**In order to keep the original styles **/
.swal-button--cancel {
  background-color: #e8e8e8 !important;
}

.swal-button--cancel:not([disabled]):hover {
  background-color: #e8e8e8 !important;
}

.swal-button--danger {
  background-color: #e64942 !important;
}

.swal-button--danger:not([disabled]):hover {
  background-color: #e64942 !important;
}

/**Center buttons in the window**/
.swal-footer{
  text-align: center;
}

/**Center text in the window.**/
.swal-text {
  text-align: center;
}