.Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #56595A;
  color: white;
  font-size: 13px;
  font-weight: bold;
  align-items: center;
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.FooterLink {
  color: #FFFFFF !important;
  text-decoration: underline;
  cursor: pointer;
}

.FooterInnerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

