.header {
    margin: 16px auto;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 16px;
}

.headerTitle {
    position: absolute;
    font-family: Lato;
    font-size: 30px;
    color: #56595A;
    font-weight: bold;
    margin: 40px auto;
}

.headerSubtitle {
    position: absolute;
    font-family: Lato;
    font-size: 12px;
    color: #389FD3;
    margin: 70px auto;
}

.headerButton {
    float: right;
    background-color: #F7861C !important;
    border: 2px solid #F7861C !important;
    border-radius: 4px;
    vertical-align: middle;
    width: 280px;
    height: 60px;
    padding: 0;
    box-shadow: none;
    cursor: pointer;
 }

.headerButton:disabled {
    float: right;
    background-color: #F6BA81 !important;
    border: 2px solid #F6BA81 !important;
    border-radius: 4px;
    vertical-align: middle;
    width: 280px;
    height: 60px;
    padding: 0;
    cursor: not-allowed;
}

.headerButtonConnected {
    float: right;
    background-color: #ffffff !important;
    border: 2px solid #ffffff !important;
    border-radius: 4px;
    vertical-align: middle;
    width: 280px;
    height: 60px;
    padding: 0;
    cursor: default;
}

.headerButtonImage {
    margin-left: 10px;
    margin-top: -5px;
}

.headerButtonText {
    margin-left: 2px;
    color: #FFFF;
    font-family: Lato;
    font-size: 17px;
    
}
.headerButtonTextConnected {
    margin-left: 2px;
    color: #56595A;
    font-weight: bold;
    font-family: Lato;
    font-size: 17px;    
}
