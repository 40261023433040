.Members {
    margin: 20px auto;
    font-size: 20px;
}

.span {
    color: #56595A;
    line-height: normal !important;
    margin: auto !important;
}

.MembersButton {
    background-color: #979EA2;
    border: 2px solid #979EA2;
    border-radius: 4px;
    vertical-align: middle;
    padding: 0;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    font-size: 13px;
    margin-left: 20px;
    width: 150px;
    height: 40px;
}

.MembersButton:hover {
    color: #ffffff;
}

.MembersButton:disabled {
    background-color: #DDDDDD;
    color: #ffffff;
    border: 2px solid #DDDDDD;
    cursor: not-allowed;
}
